.ant-picker-time-panel {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iframe-content {
  min-height: calc( 100vh - 40px);
  min-width: calc( 100vw - 270px);
}

.ant-menu-item {
  font-weight: bold;
}

.ant-menu-item-active {
  color: #4fad96 !important;
}

/* .ant-layout-sider-children {
  padding-top: 20px !important;
} */

.ant-menu-item {
  padding-left: 15px !important;
}

.onb-layout-content {
  min-height: 100vh !important;
  padding: 0 100px 0 100px;
}

.app-layout-content {
  min-height: calc(100vh - 58px) !important;
  padding: 20px 20px 20px 20px;
  background: white;
}

.threads {
  min-height: calc(100vh - 58px) !important;
  max-height: calc(100vh - 58px) !important;

  background: white;
}

.logo {
  margin: 8px 0 0 18px;
}

.logo-name {
  padding: 15px 0 0 0;
}

.nav-menu {
  padding: 5px 0 0 0;
}

.nav {
  min-height: 50px;
}

.avatar {
  margin: 10px 0 0 0;
}

.steps-content {
  min-height: 420px;
  margin-top: 16px;
  padding: 16px 16px 16px 16px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.onboard-action {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.ant-upload-list-item-name {
  max-width: 800px;
}

.ant-upload-list-item {
  justify-content: center;
  max-width: 400px;
}

.ant-upload-list-item-name {
  overflow: visible !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
}


.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.tablePop {
  width: 200px;
}

.ant-table-cell {
  padding: 5px 5px 5px 5px !important;
}

.ant-table-tbody {
  padding-right: 0 !important;
}

.editable-row .ant-form-item {
  margin-bottom: 0;
}

.editable-cell-value-wrap {
  cursor: pointer;
}

.ant-collapse-content-box {
  padding: 0 0 0 0 !important;
}

.bold-border {
  border-left: 2px solid black;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}


@keyframes spinAndBounce {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(360deg); }
  30% { transform: translateY(-20px); }
  40% { transform: translateY(0); }
  45% { transform: translateY(-20px); }
  55% { transform: translateY(0); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spinAndBounce 3s ease-in-out infinite; /* Change to infinite */
  transform-origin: center center;
}



.checkmark-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 6;
  stroke: #4fad96;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4fad96;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 10px #4fad96;
  }
}